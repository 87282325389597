import Vue from 'vue';
import App from '@/App.vue';
import RplGlobal from '@dpc-sdp/ripple-global';
import router from '@/router';
import store from '@/store';
import VueGtag from 'vue-gtag';
import config from '@/config';

Vue.config.productionTip = false;
Vue.use(RplGlobal, { viclogo: false });

config().then((c) => {
  // Temporary redirect, remove when v2 migration process is complete
  const pathPieces = window.location.pathname.split('/');
  if (
    pathPieces.length > 1 &&
    pathPieces[1] &&
    c.v2Dashboards.includes(pathPieces[1])
  ) {
    window.location.href = `/v2${window.location.pathname}${window.location.search}`;
  }

  Vue.use(
    VueGtag,
    {
      config: { id: 'GTM-K3NDNWK2' },
      disableScriptLoad: !c.featureFlags.collectAnalytics,
    },
    router,
  );
});

new Vue({
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');
