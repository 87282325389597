
/* eslint-disable @typescript-eslint/no-unused-vars,no-unused-vars */
import Vue from 'vue';
import { RplAlertBase } from '@dpc-sdp/ripple-alert';
import {
  Page,
  Widget,
  Dashboard,
  WidgetProperty,
} from '@/models/dashboard.model';
import { DataFromApi, getData, hasData } from '@/api/data';
import {
  hasGridLayout,
  pageLayoutStyle,
  widgetLayoutStyle,
} from '@/helpers/layout';
import { registerAsyncWidget } from '@/components/widgets';
import getConfig from '@/config';
import { Configuration } from '@/config/types';
import { templateConfigProperties } from '@/helpers/widget-config-utils';
import PreviewAction from '@/components/table/actions/PreviewAction.vue';
import NotFound from '@/components/pages/NotFound.vue';
import WidgetContainer from '@/components/pages/WidgetContainer.vue';

registerAsyncWidget('ACCORDION_WIDGET', 'accordion/AccordionWidget.vue');
registerAsyncWidget('API_KEY_WIDGET', 'data-exchange/ApiKeyWidget.vue');
registerAsyncWidget('AUDIT_TABLE_WIDGET', 'audits/AuditsWidget.vue');
registerAsyncWidget('BACK_LINK_WIDGET', 'BackLinkWidget.vue');
registerAsyncWidget('BATCH_DELETE_WIDGET', 'permits/delete/DeleteWidget.vue');
registerAsyncWidget('BATCH_UPLOAD_WIDGET', 'permits/upload/UploadWidget.vue');
registerAsyncWidget(
  'BATCH_UPLOAD_HISTORY_WIDGET',
  'permits/upload-history/UploadHistoryWidget.vue',
);
registerAsyncWidget('COMMENT_WIDGET', 'comms/CommentWidget.vue');
registerAsyncWidget('COMMENT_HISTORY_WIDGET', 'comms/CommentHistoryWidget.vue');
registerAsyncWidget(
  'CONTROLLABLE_TABLE_WIDGET',
  'table/ControllableTableWidget.vue',
);
registerAsyncWidget(
  'CREATE_DASHBOARD_WIDGET',
  'admin/CreateDashboardWidget.vue',
);
registerAsyncWidget('CREATE_PAGE_WIDGET', 'admin/CreatePageWidget.vue');
registerAsyncWidget('DASHBOARD_LIST_WIDGET', 'admin/DashboardListWidget.vue');
registerAsyncWidget(
  'DATA_EXCHANGE_WIDGET',
  'data-exchange/DataExchangeWidget.vue',
);
registerAsyncWidget('DATA_UPLOAD_WIDGET', 'data-exchange/DataUploadWidget.vue');
registerAsyncWidget(
  'DATA_UPLOAD_HISTORY_WIDGET',
  'data-exchange/DataUploadHistoryWidget.vue',
);
registerAsyncWidget('DIAGRAM_WIDGET', 'application-diagram/DiagramWidget.vue');
registerAsyncWidget(
  'DOCUMENT_TEMPLATE_BUILDER_WIDGET',
  'documents/DocumentTemplateBuilderWidget.vue',
);
registerAsyncWidget(
  'DOCUMENT_TEMPLATE_WIDGET',
  'documents/DocumentTemplateWidget.vue',
);
registerAsyncWidget('EDIT_DASHBOARD_WIDGET', 'admin/EditDashboardWidget.vue');
registerAsyncWidget('EDIT_PAGE_WIDGET', 'admin/EditPageWidget.vue');
registerAsyncWidget('EDIT_RESOURCE_WIDGET', 'resource/EditResourceWidget.vue');
registerAsyncWidget('EMAIL_HISTORY_WIDGET', 'comms/EmailHistoryWidget.vue');
registerAsyncWidget(
  'EMAIL_TEMPLATE_BUILDER_WIDGET',
  'comms/EmailTemplateBuilderWidget.vue',
);
registerAsyncWidget('EMAIL_WIDGET', 'comms/EmailWidget.vue');
registerAsyncWidget(
  'FILTER_CONTROLLER_WIDGET',
  'controller/FilterControllerWidget.vue',
);
registerAsyncWidget('FORMIO_WIDGET', 'forms/FormIOWidget.vue');
registerAsyncWidget('FORM_VIEWER_WIDGET', 'support/FormViewerWidget.vue');
registerAsyncWidget('GENERIC_TABLE_WIDGET', 'table/GenericTableWidget.vue');
registerAsyncWidget('GEOJSON_MAP_WIDGET', 'spatial/GeoJsonMapWidget.vue');
registerAsyncWidget('GROUP_EMAIL_WIDGET', 'comms/GroupEmailWidget.vue');
registerAsyncWidget(
  'IMPORT_DASHBOARD_WIDGET',
  'admin/ImportDashboardWidget.vue',
);
registerAsyncWidget('LOOKUP_WIDGET', 'lookup/LookupWidget.vue');
registerAsyncWidget('NAVIGATION_PATH_WIDGET', 'NavigationPathWidget.vue');
registerAsyncWidget('NAVIGATION_TILE_WIDGET', 'NavigationTileWidget.vue');
registerAsyncWidget('NAVIGATION_WIDGET', 'NavigationWidget.vue');
registerAsyncWidget('PAGE_WIDGET', 'admin/PageWidget.vue');
registerAsyncWidget('PPARS_API_KEY_WIDGET', 'permits/PparsApiKeyWidget.vue');
registerAsyncWidget('RESOURCE_LIST_WIDGET', 'resource/ResourceListWidget.vue');
registerAsyncWidget(
  'RESOURCE_REPORT_WIDGET',
  'reports/ResourceReportWidget.vue',
);
registerAsyncWidget(
  'RESOURCE_UPLOAD_WIDGET',
  'resource-upload/ResourceUploadWidget.vue',
);

registerAsyncWidget(
  'SIGNATURE_UPLOAD_WIDGET',
  'user-attachments/UserSignatureWidget.vue',
);

registerAsyncWidget(
  'SIMPLE_DATA_TABLE_WIDGET',
  'table/SimpleDataTableWidget.vue',
);
registerAsyncWidget('TAB_CONTENT_WIDGET', 'TabContentWidget.vue');
registerAsyncWidget('TABLE_CHART_WIDGET', 'chart/TableChartWidget.vue');
registerAsyncWidget('TABLE_WIDGET', 'table/TableWidget.vue');
registerAsyncWidget(
  'USER_GROUP_MANAGEMENT_WIDGET',
  'table/UserGroupManagementWidget.vue',
);
registerAsyncWidget('WIDGET_CONTAINER', 'WidgetContainerWidget.vue');
registerAsyncWidget(
  'RESOURCE_ATTACHMENTS_WIDGET',
  'resource-attachments/ResourceAttachmentsWidget.vue',
);
registerAsyncWidget(
  'RESOURCE_COPY_WIDGET',
  'resource-copy/ResourceCopyWidget.vue',
);
registerAsyncWidget(
  'RESOURCE_COLLABORATORS_WIDGET',
  'resource-collaborators/ResourceCollaboratorsWidget.vue',
);

// register table action here to prevent cyclical import
Vue.component('preview-action', PreviewAction);

type Data = {
  config?: Configuration;
};
type Computed = {
  dashboard?: Dashboard;
  page?: Page;
  widgets: Widget[];
  pageStyle?: {};
  hasGridLayout: boolean;
  message?: string;
};
type Methods = {
  configurePage: (page: Page) => void;
  widgetCode: (widget: Widget) => string;
  widgetStyle: (widget: Widget) => {} | undefined;
  onCloseMessage: () => void;
  templateProperties: (properties: WidgetProperty[]) => WidgetProperty[];
};
type Props = { pageCode: string };

export default Vue.extend<Data, Methods, Computed, Props>({
  props: { pageCode: String },
  data() {
    return { config: undefined };
  },
  components: { NotFound, WidgetContainer, RplAlertBase },
  computed: {
    dashboard() {
      const dataFromApi: DataFromApi<Dashboard> =
        this.$store.state.dashboard.dashboardFromApi;
      return hasData(dataFromApi) ? getData(dataFromApi) : undefined;
    },
    page() {
      return this.dashboard?.pages?.find((page) => page.code === this.pageCode);
    },
    widgets() {
      return this.page?.widgets || [];
    },
    pageStyle() {
      if (this.page && this.hasGridLayout) {
        return pageLayoutStyle(this.page);
      }
      return undefined;
    },
    hasGridLayout() {
      return hasGridLayout(this.page);
    },
    message() {
      return this.$store.state.page.message;
    },
  },
  methods: {
    configurePage(page) {
      this.$store.commit('setHeaderText', page.title);
      this.$store.commit('setSubText', page.subTitle);
      if (this.pageCode === this.dashboard?.defaultPageCode) {
        this.$store.commit('setBreadcrumbs', []);
      } else {
        this.$store.commit('setBreadcrumbs', [{ text: page.breadCrumb }]);
      }
    },
    widgetCode(widget) {
      return `${widget.type}_${widget.code}_${this.widgets
        .filter((w) => w.type === widget.type)
        .indexOf(widget)}`;
    },
    widgetStyle(widget) {
      return widgetLayoutStyle(widget);
    },
    onCloseMessage() {
      this.$store.commit('clearPageMessage');
    },
    templateProperties(properties) {
      if (this.config) {
        return properties
          ? templateConfigProperties(properties, this.config)
          : [];
      }
      throw new Error('Attempting to use config before loaded');
    },
  },
  mounted() {
    if (this.page) {
      this.configurePage(this.page);
    }
  },
  async created() {
    this.config = await getConfig();
  },
  watch: {
    pageCode() {
      if (this.page) {
        this.configurePage(this.page);
      }
    },
  },
});
